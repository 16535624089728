.header {
  display: flex;
  height: 75px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 0px;
  border-width: 0px;
  /* Styles added to make header fixed */
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;
  background-color: #FFC729;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.nav-left {
  flex-grow: 1;
  padding-left: 15px;
  display:flex;
  flex-direction: column;
}

.logo-lb {
  padding-right: 22.5px;
  /* border-right: 2px solid black; */
}

.nav-right {
  flex-grow: 1;
  padding-right: 15px;
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.nav-right > *:not(:last-child) {
  margin-right: 10px;
}

.nav-right a {
  display: flex;
}

.twitter {
  display: flex;
  align-items: center;
  color: black;
  font-family: 'Nunito-Black';
  font-size: 0.8rem;
  text-decoration: none;
}

.logo {
  max-width: 150px;
  flex-grow: 1;
}

.handle{
  display: none;
}

.nav-footer{
  display:none;
}


@media screen and (min-width: 48em) {
  .twitter {
    font-size: 1rem;
  }
  .logo {
    display: inline-block;
    max-width: initial;
  }
  .handle{
    display: initial;
  }
  .logo-lb-footer img{
    width:200px;
  }
  

  .nav-footer{
    display: block;
    position:fixed;
    bottom:3%;
    right: 30px;
  }
}

@media screen and (min-width: 60em) {
  .nav-footer{
    right: 80px;
  }

}

@media screen and (min-width: 72em) {}

@media screen and (min-width: 84em) {}

@media screen and (min-width: 96em) {
  
}
