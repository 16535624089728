@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.page-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.page-content {
  flex-grow: 1;
}

/* Styles added to compensate for fixed header*/
.container {
  display: flex;
  flex-direction: row;
  flex: auto;
  background: #f0f2f5;
  flex-grow: 1;
  min-height: 0;
  height: calc(100vh - 75px);
}

.left-container {
  position: relative;
  /* overflow: scroll; */
  /* padding-bottom: 75px;
  height: 100vh;  */
  /* -webkit-overflow-scrolling: touch; */
}

.inquiry-container {
  position: relative;
  display: flex;
  height: auto;
  background-color: white;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  padding: 10px;
}

.title-container {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 10px 10px 0px 10px;
}

.title {
  font-family: "Nunito-SemiBoldItalic";
  font-size: 1rem;
  flex-grow: 3;
}

.misc-1 {
  width: 150px;
  height: 150px;
}

.misc-art {
  display: none;
  flex-grow: 1;
}

.search-container {
  width: 100%;
  padding: 10px 10px;
}

.input-container {
  display: flex;
  align-items: center;
}

.input {
  width: 100%;
  height: 50px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1rem;
  text-indent: 10px;
  border: none;
  outline: none;
  font-weight: 500;
}
.underline {
  position: relative;
  display: block;
  height: 2px;
  width: 100%;
  background-color: #d8d8d8;
}
.underline.blink {
  background-color: #ffc729;
  animation: blink 0.5s infinite;
}

.geo-button {
  border: none;
  margin-left: 5px;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
}
.geo-button img {
  height: 100%;
}
.blink {
  animation: blink 1s infinite;
}

.loader-container {
  padding-top: 5%;
}

.map-container {
  display: initial;
  width: 100%;
  flex-grow: 1;
}

.more-info {
  padding-top: 2.5rem;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
}

.more-info p {
  margin: 0 auto;
  font-family: "Nunito-SemiBold";
  color: black;
}

.more-info a {
  text-decoration: none;
}

.info span {
  display: block;
  padding-bottom: 10px;
}

.contact-container {
  width: 100%;
}

.connect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
}

.list {
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}

.misc-art2 {
  position: absolute;
  padding: 10px 0px;
  /* bottom:15%;
  right: 2%; */
}

.misc-2 {
  width: 200px;
}

.tab {
  margin-top: 1rem;
  width: 100%;
}

.tab h3 {
  text-align: center;
}

.tab-nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.tab-button {
  width: 100%;
  outline: none;
  border: none;
  font-size: 1rem;
  padding: 1rem 0.5rem;
  background: #f5f5f5;
  cursor: pointer;
  font-family: "Nunito-Black";
  color: #696969;
  transition: all ease-in-out 0.3s;
}

.tab-button[active="1"] {
  background: black;
  color: white;
}

/* Tablet 768px */
@media screen and (min-width: 48em) {
  .title {
    font-size: 1.1rem;
  }

  .contact,
  .connect {
  }

  .mobile {
    display: initial;
  }

  .misc-art2 {
    display: none;
  }
}

@media screen and (min-width: 60em) {
}

@media screen and (min-width: 72em) {
}

@media screen and (min-width: 84em) {
}

@media screen and (min-width: 96em) {
  .misc-art {
    display: initial;
  }
}
