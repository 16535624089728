*, *:before, *:after {
	box-sizing: border-box;
}

body {
  background-color: #f5f5f5;
  margin: 0;
  padding: 75px 0 0 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

html, body, #root {
  max-height: 100%;
}