.info {
    display: flex;
    flex-direction: column;
  }
  
  .name {
    margin-bottom: 7.5px;
  }
  .title {
    margin-bottom: 5px;
  }
  
  .address {
    margin-bottom: 8px;
  }
  
  .info .hour {
    margin-bottom: 8px;
  }
  
  .info .hour span {
    margin-bottom: 4px;
  }


.mapButton{
    position: absolute;
    top:10px;
    right:80px;
    width:220px;
    height:40px;
    background-color:white;
    border:none;
    font:inherit;
    font-size: 1rem;
    outline:none;
    transition: all 0.5s ease;
    cursor:pointer;
    padding: 0;
    /* box-shadow:2px 2px 20px 0px rgba(0,0,0,0.75) ; */
  }

  .copyButton{
    position: absolute;
    top:60px;
    right:80px;
    width:220px;
    height:40px;
    background-color:#353535;
    color:#FFC729;
    border:none;
    font:inherit;
    font-weight: 700;
    font-size: 1rem;
    outline:none;
    transition: all 0.5s ease;
    cursor:pointer;
    padding: 0;
    /* box-shadow:2px 2px 20px 0px rgba(0,0,0,0.75) ; */
  }

  .copied{
    position: absolute;
    top:60px;
    right:80px;
    width:220px;
    height:40px;
    background-color:#FFC729;
    color:#353535;
    border:none;
    font:inherit;
    font-weight: 700;
    font-size: 1rem;
    outline:none;
    transition: all 0.5s ease;
    cursor:pointer;
    padding: 0;
  }

  .copyAlert{
    position: absolute;

  }

  .mapButtonContents {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .mapButtonContents::before {
    content: "";
  }
  
  .mapButtonContents::after {
    content: "";
  }

  .fetchButton{
    position: absolute;
    top:10px;
    right:80px;
    width:220px;
    height:40px;
    background-color:#353535;
    border:none;
    font:inherit;
    font-size: 1rem;
    outline:none;
    transition: all 0.5s ease;
    cursor:pointer;
    font-weight: 700;
    color:#FFC729;
    /* box-shadow:2px 2px 20px 0px rgba(0,0,0,0.75) ; */
  }

.mapButton span{
  font-weight: 500;
}

.checked i {
  width: 20px;
  height: 20px;
  margin:2px 5px;
  background:#FFC729;
  text-align: center;
  border:2px solid #FFC729;
}

.unchecked i {
  width: 20px;
  height: 20px;
  margin:2px 5px;
  background:transparent;
  text-align: center;
  border:2px solid #353535;
  color:transparent;
}

.mapContainer{
  height:100%;
  width:100%;
  display: block;
}