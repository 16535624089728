.card {
  min-height: 150px;
  display: flex;
  justify-content: space-around;
  flex: 1;
  text-decoration: none;
  padding: 1rem;
  margin: 0.5rem 1rem;
  background-color: #fff;
  box-shadow: 1px 1px 0.5rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-right: 20px; */
  flex: 1 .75 80%;
}

.title {
  flex-grow: 1;
  display: flex;
}

.title .name {
  font-size: 1rem;
  color: black;
  line-height: 1;
  text-transform: uppercase;
  font-family: 'Nunito-Black';
  text-decoration: none;
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden; */
}

.desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin-bottom: 5px;
  /* padding: 5px 0px; */
  /* min-height:90px; */
}

.address {
  font-family: "Nunito-Italic";
  font-size: .85rem;
  display: block;
  padding: 10px 0px;
}

.description {
  display: block;
  font-family: "Nunito";
  font-size: .85rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.actions {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button{
  flex:1;
  align-self: center;
  font-family: 'Nunito-Black';
  text-transform: uppercase;
  border-radius: 3px;
  padding: 10px 0px;
  text-decoration: none;
  font-size: 1rem;
  display: block;
  text-align: center;


}

.gift-link {
  background-color: #FFC729;
  border:2px solid #FFC729;
  box-shadow: 0 2px 4px 0 #D8D8D8;
  color:#353535;
  transition: all 0.7s cubic-bezier(.25,.8,.25,1);
  margin:0px 10px 0px 0px;
}

.donate-link{
  margin:0px 0px 0px 10px;
  color:#353535;
  border:2px solid #353535;
  transition: all 0.7s cubic-bezier(.25,.8,.25,1);
}

.order-link{
  /* margin:0px 0px 0px 10px; */
  background-color: #FFC729;
  border:2px solid #FFC729;
  box-shadow: 0 2px 4px 0 #D8D8D8;
  color:#353535;
  transition: all 0.7s cubic-bezier(.25,.8,.25,1);
}

.tab-title{
  text-align: center;
}


.gift-link:hover {
  box-shadow: 0 3px 8px rgba(0,0,0,0.25), 0 2px 4px rgba(0,0,0,0.22);
}

.image {
  flex-shrink: 1.5;
  width: 100%;
  min-height: 100%;
}

.image>div:first-child {
  height: 100%;
  background-color: #f3f3f3;
}

.image > div {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}


/* Tablet 768px */

@media screen and (min-width: 48em) {
  .title {
    flex-grow: 0;
  }
  
  .title .name {
    font-size: 1.05rem;
  }

  .actions {
    flex-grow: 0;
  }
}

@media screen and (min-width: 60em) {
  .title .name {
    font-size: 1.10rem;
  }

}

@media screen and (min-width: 72em) {
  .title .name {
    font-size: 1.15rem;
  }
}

@media screen and (min-width: 84em) {}

@media screen and (min-width: 96em) {
  .card {
    height: 150px;
  }
}
